import React from "react"
import { graphql } from "gatsby"
import Styled from "styled-components"

import Layout from "../../../components/Layout"
import SEO from "../../../components/Seo"
import RuledBreak from "../../../components/RuledBreak"
import { ButtonTrace } from "../../../components/Button"

const UL = Styled.ul`
  li {
	font-size: 2rem;
	cursor: pointer;
	&:hover {
		opacity: 0.6;
		font-weight: bold;
	}
  }
`

const Membership = ({ data }) => {
  const header = data.header.edges[0].node.childImageSharp.fluid
  return (
    <Layout
      fluid={header}
      alt={"Membership Header"}
      height={600}
      heightMobile={300}
    >
      <SEO
        title="Membership"
        keywords={["Membership", "Beyond The Apprenticeship", "Matt Pehrson"]}
      />
      <div className="row">
        <div className="col-md-12 py-3">
          <RuledBreak>Membership</RuledBreak>
        </div>
      </div>

      <div className="row">
        <div className="col-md-10 mr-auto ml-auto text-center py-3">
          <h3>
            You are about to take an important step in improving your career!
          </h3>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 text-center py-3">
          <h2 className="bold-underline">Membership Includes:</h2>
        </div>
        <div className="col-md-6 mr-auto ml-auto py-3">
          <UL>
            <li>Two Monthly Training Videos</li>
            <li>Facebook Group Access</li>
            <li>Monthly Training Webinar</li>
            <li>$5.00 Off Past Videos</li>
            <li>Access to Special Documents</li>
          </UL>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 text-center py-3">
          <ButtonTrace to="/education/membership/join" btn={3}>
            Sign Up Now
          </ButtonTrace>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MembershipQuery {
    header: allFile(filter: { name: { eq: "headerMembership_1300x682" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1300) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

export default Membership
